import { ContentSection } from 'designsystem/components/4-habitats/ContentSection';
import { GridCell } from 'designsystem/components/4-habitats/Grid';
import { ModuleFeature } from 'features/Modules/DynamicModule';

export const ContentSectionFeature: React.FC<Content.ContentSection> = ({ content, settings, noBackground }) => {
	const { content: contentModules, title } = content?.properties ?? {};
	const { noTopMargin } = settings?.properties ?? {};
	const noTopMarginForce = contentModules?.[0]?.documentType === 'calculatorSelector';
	return (
		<ContentSection title={title} noTopMargin={noTopMarginForce ? true : noTopMargin}>
			{contentModules?.map((contentModule, index) => {
				const { properties } = contentModule?.settings ?? {};
				if (contentModule?.settings && 'desktopWidth' in properties && 'mobileWidth' in properties) {
					const { desktopWidth, mobileWidth } = properties ?? {};

					return (
						<GridCell key={index} desktopWidth={desktopWidth} mobileWidth={mobileWidth}>
							<ModuleFeature contentModule={contentModule} />
						</GridCell>
					);
				}

				return <ModuleFeature contentModule={contentModule} key={index} additionalProps={{ noBackground }} area="contentSection" />;
			})}
		</ContentSection>
	);
};
