import classNames from 'classnames';
import * as React from 'react';

import styles from './Grid.module.scss';

export interface GridProps {
	className?: string;
	wrap?: boolean;
	container?: boolean;
	nogutter?: boolean;
	full?: boolean;
	spaceBetween?: boolean;
	children?: React.ReactNode;
}

export const Grid: React.FC<GridProps> = ({ className, wrap, container, nogutter, full, spaceBetween, children }) => (
	<div
		className={classNames(
			styles.Grid,
			{ [styles.Grid___wrap]: wrap },
			{ [styles.Grid___container]: container },
			{ [styles.Grid___noGutter]: nogutter },
			{ [styles.Grid___full]: full },
			{ [styles.Grid___spaceBetween]: spaceBetween },
			className,
		)}
	>
		{children}
	</div>
);
