import classNames from 'classnames';

export type GridCellWidths = '10' | '25' | '33' | '50' | '66' | '75' | '90' | '100' | '';
export interface GridCellProps {
	className?: string;
	children: React.ReactNode;
	center?: boolean;
	mobileWidth?: GridCellWidths;
	desktopWidth?: GridCellWidths;
	borderRight?: boolean;
	borderLeft?: boolean;
	padded?: boolean;
}

export const GridCell: React.FC<GridCellProps> = ({
	className,
	mobileWidth = '100',
	desktopWidth = '100',
	children,
	borderLeft,
	borderRight,
	padded,
}) => (
	<div
		className={classNames(
			'u-grid__cell',
			`u-grid__cell--width-${mobileWidth}`,
			`u-grid__cell--width-${desktopWidth}@md`,
			borderLeft && 'u-grid__cell--borderLeft',
			borderRight && 'u-grid__cell--borderRight',
			padded && 'u-grid__cell--padded',
			className,
		)}
	>
		{children}
	</div>
);
